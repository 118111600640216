
// action types
const ACCESSTOKEN = 'ACCESSTOKEN'


// reducer
const initialState = {

    accesstoken: '',

}
export default function (state = initialState, action) {
    switch (action.type) {
        case ACCESSTOKEN:
            return { ...state, accesstoken: action.accesstoken }
        default:
            return state;
    }
}

export function accesstoken(token) {
    return { type: ACCESSTOKEN, accesstoken: token }
}
