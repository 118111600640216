// import 'core-js';

import React, { Component } from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import './App.css';
// import './style/theme.less';

import Myrouter from './router/Myrouter'
import reducer from './reducers/index'

// import './style/bootstrap.min.css'

const store = createStore(reducer)

class App extends Component {

  componentDidMount(){
    window.onerror=e=>{
      console.log(e, this)
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Myrouter />
      </Provider>
    )
  }
}

export default App