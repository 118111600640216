import React, { useEffect, } from 'react';
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router';
import parseParams from '../../methods/paramParse';
import { get, getRefreshToken, post, setRefreshToken, setToken } from '../../netApi/fetch';
import { accesstoken } from '../../reducers/index'
import { ready } from '../Method/alipay';

interface props extends RouteComponentProps {
    setAccessToken: (token: string) => void
    accesstoken: string
}

function Login(props: props) {
    useEffect(() => {
        if (props.accesstoken) return
        const data = parseParams(props.location.search)
        if (data.code) {
            post('/wechatapi/login', { code: data.code, app: 'jieligo_public' }).then(res => {
                props.setAccessToken(res.accesstoken)
                setToken(res.accesstoken)
                setRefreshToken(res.refreshtoken)
            }).catch(() => {
                props.history.push(props.location.pathname)
            })
            return
        }
        const token = getRefreshToken()
        if (token) {
            get('/user/getaccesstoken', { refreshtoken: token }).then(res => {
                props.setAccessToken(res.accesstoken)
                setToken(res.accesstoken)
            }).catch(e => {
                login()
            })
        } else {
            login()
        }

        function login() {
            const ua = navigator.userAgent.toLowerCase();
            if ((/MicroMessenger/i).test(ua)) {
                const s = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6cf3b69eb2efe6e7&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base#wechat_redirect`
                window.location.href = s
            } else {
                ready(function () {
                    ap.getAuthCode({
                        appId: '2021002129667424',
                        scopes: ['auth_base'],
                    }, function (res) {
                        post('/alipayapi/login', {
                            app: 'zfb_shh',
                            code: res.authCode,
                        }).then(data => {
                            props.setAccessToken(data.accesstoken)
                            setToken(data.accesstoken)
                            setRefreshToken(data.refreshtoken)
                        }).catch(e => { ap.alert('error' + JSON.stringify(e)); })
                    })
                })
            }
        }
    }, [props])

    return <></>
}

const mapStateToProps = (state, ownProps) => {
    return {
        accesstoken: state.accesstoken
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setAccessToken: (data) => {
            dispatch(accesstoken(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))