import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import { match, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import parseParams from '../../methods/paramParse';
import { get, getToken, post } from '../../netApi/fetch';
import wxpay from '../../data/pay';
import { ready } from '../Method/alipay';
interface props extends RouteComponentProps {
    user: user
    accesstoken?: string
    match: Match
}

interface Match extends match {
    params: {
        id: string
    }
}

const Borad = styled.div`
    position: fixed; 
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 256px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    background-color: #f5f5f5;
    padding: 6px 3px;
    box-sizing: border-box;
    &>div{
        user-select: none;
        background-color: #fff;
        border-radius: 3px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: background-color .2s;
        &:active{
            background-color: #f5f5f5;
        }
    }
`

const PayButton = styled.div<{ active: boolean, loading: boolean }>`
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 4;
    grid-column-end: 4;
    background: ${props => props.active ? '#FA503C' : '#eee'} !important;
    color: ${props => props.active ? '#fff' : '#999'} !important;
    transition: background-color .2s;
    &:active{
        background-color: #E04735  !important;
    }
    ${props => props.loading ? 'background-color: #E04735 !important;' : ''}
`

function Index(props: props) {
    // @ts-ignore
    if (window.WeixinJSBridge) {
        // @ts-ignore
        window.WeixinJSBridge.on('onPageStateChange', function (res) {
            console.log('res is active', res.active)
        })
    }
    // 页面title
    useEffect(() => {
        document.title = '向商家付款'
        document.body.style.backgroundColor = '#ededed'
        return () => {
            document.title = ''
            document.body.style.backgroundColor = '#fff'
        }
    }, [])

    // 获取授权
    // const [config, setConfig] = useState(false) // 是否已经获取授权
    // useEffect(() => {
    //     post('/huser/param', {
    //         url: encodeURIComponent(window.location.href.split('#')[0])
    //     }).then(res => {
    //         wx.error(res => {
    //             console.log(res);
    //         })
    //         wx.ready(() => {
    //             setConfig(true)
    //         })
    //         wx.config({
    //             debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //             jsApiList: ['updateAppMessageShareData', 'chooseWXPay'], // 必填，需要使用的JS接口列表
    //             openTagList: ['wx-open-launch-weapp'],
    //             appId: res.appId, // 必填，公众号的唯一标识
    //             timestamp: res.timestamp, // 必填，生成签名的时间戳
    //             nonceStr: res.nonce_str, // 必填，生成签名的随机串
    //             signature: res.signature,// 必填，签名
    //         })
    //     })
    // }, [])

    const [price, setPrice] = useState('')

    useEffect(() => {
        const num = price.split('.')
        let p: string = price
        if (num.length > 2) {
            p = Number(num[0] + '.' + (num[1] || '')).toFixed(2)
        } else if (num[1] && num[1].length > 2) {
            p = Number(num[0] + '.' + (num[1] || '')).toFixed(2)
        }
        setPrice(p)
        if (props.accesstoken) {
            post('/scanpay/getprice', { payprice: p, sid: props.match.params.id }).then((res) => {

            }).catch(alert)
        }
    }, [price, props.match, props.accesstoken])

    const active = !isNaN(Number(price)) && (Number(price) > 0)

    const delcount = useRef<number>()
    const [loading, setLoading] = useState(false)

    const topay = useCallback(() => {
        const p = (Number(price) * 100).toFixed(0)
        if (Number(price) <= 0) return
        if (loading) return
        setLoading(true)
        post('/scanpay/createorder', { payprice: p, sid: props.match.params.id }).then((res) => {
            const ua = navigator.userAgent.toLowerCase();
            const prepay = `/wechatpay/prepay?${(/MicroMessenger/i).test(ua) ? 'app=jieligo_public' : 'app=zfb_shh'}`
            post(prepay, { fee: res.payprice, from: 'scan_pay', orderid: res.orderid, }).then(res => {
                wxpay(res.jsparams, () => {
                    setLoading(false)
                    props.history.push(`/success?price=${(Number(p) / 100).toFixed(2)}`)
                }, res => { console.log(res); setLoading(false); })
                ready(function () {
                    AlipayJSBridge.call("tradePay", {
                        tradeNO: String(res.jsparams)
                    }, function (result) {
                        setLoading(false)
                        if (result.resultCode === '9000') {
                            props.history.push(`/success?price=${(Number(p) / 100).toFixed(2)}`)
                        }
                    });
                })
            })
        }).catch(e => {
            alert(e)
            setLoading(false)
        })
    }, [price, props.match, props.history, loading])

    const params = parseParams(props.location.search)
    const [name, setName] = useState(params.name || '')
    const [logo, setLogo] = useState(decodeURIComponent(params.logo || ''))

    useEffect(() => {
        if (!props.accesstoken) return
        get('/scanpay/view', { sid: props.match.params.id, }).then(res => {
            setName(res.name)
            setLogo(res.logo)
        }).catch(alert)
    }, [props.accesstoken, props.match])

    return <>
        <div className='flex-box' style={{ margin: '15px 20px ', color: '#333' }}>
            {!!logo && <img alt='' className='img-cir' src={logo + '?imageView2/5/400'} style={{ width: 30, height: 30, marginRight: 10 }} />}
            <div style={{ fontSize: 14, fontWeight: 600, }}>
                {name}
            </div>
        </div>
        <div style={{ background: '#fff', borderRadius: 10, padding: '15px 20px', margin: 15, userSelect: 'none' }}>
            <div style={{ marginBottom: 10, }}>请输入金额</div>
            <div>
                <span style={{ fontSize: 32, fontWeight: 600 }}>￥</span>
                <span style={{ fontSize: 32, fontWeight: 600 }}>{price}</span>
            </div>
        </div>
        <Borad>
            <div onClick={() => { setPrice(price + '1') }}>1</div>
            <div onClick={() => { setPrice(price + '2') }}>2</div>
            <div onClick={() => { setPrice(price + '3') }}>3</div>
            <div onTouchStart={() => {
                let index = price.length - 1
                setPrice(price.split('').splice(0, index).join(''))
                delcount.current = setInterval(() => {
                    index--
                    setPrice(price.split('').splice(0, index).join(''))
                }, 200)
            }} onTouchEnd={() => {
                clearInterval(delcount.current)
            }}><svg viewBox="0 0 1609 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13354" width="28" height="28"><path d="M501.116343 0h918.674286c89.175771 0 161.440914 72.031086 161.440914 160.914286v702.171428c0 88.8832-72.265143 160.914286-161.440914 160.914286h-918.674286c-43.944229 0-86.016-17.846857-116.443429-49.444571l-338.300343-351.085715a160.533943 160.533943 0 0 1 0-222.939428l338.300343-351.085715A161.704229 161.704229 0 0 1 501.116343 0z m0 87.771429c-19.982629 0-39.087543 8.104229-52.926172 22.469485l-338.3296 351.085715a72.967314 72.967314 0 0 0 0 101.346742l338.3296 351.085715c13.838629 14.336 32.943543 22.469486 52.955429 22.469485h918.645029a73.259886 73.259886 0 0 0 73.376914-73.142857v-702.171428c0-40.374857-32.855771-73.142857-73.376914-73.142857h-918.674286z m456.352914 438.974171l114.2784 114.249143a43.885714 43.885714 0 1 1-62.083657 62.0544l-114.249143-114.249143-113.810286 113.781029a43.885714 43.885714 0 0 1-62.025142 0l-0.234058-0.2048a43.885714 43.885714 0 0 1 0-62.0544l113.810286-113.810286-114.2784-114.249143a43.885714 43.885714 0 0 1 62.0544-62.0544l114.2784 114.249143 113.781029-113.781029a43.885714 43.885714 0 0 1 62.0544 0l0.2048 0.2048a43.885714 43.885714 0 0 1 0 62.0544l-113.781029 113.810286z" p-id="13355"></path></svg></div>
            <div onClick={() => { setPrice(price + '4') }}>4</div>
            <div onClick={() => { setPrice(price + '5') }}>5</div>
            <div onClick={() => { setPrice(price + '6') }}>6</div>
            <PayButton active={active} loading={loading} onClick={topay}>
                {loading ? '付款中' : '付款'}
            </PayButton>
            <div onClick={() => { setPrice(price + '7') }}>7</div>
            <div onClick={() => { setPrice(price + '8') }}>8</div>
            <div onClick={() => { setPrice(price + '9') }}>9</div>
            <div onClick={() => { setPrice(price + '0') }} style={{ gridRowStart: 4, gridRowEnd: 4, gridColumnStart: 1, gridColumnEnd: 3 }}>0</div>
            <div onClick={() => {
                if (price.split('.').length > 1) return
                setPrice(price + '.')
            }}>.</div>
        </Borad>
    </>
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        accesstoken: state.accesstoken
    }
}
export default connect(mapStateToProps,)(Index)