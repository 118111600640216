import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import { match, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import parseParams from '../../methods/paramParse';
import { get, getToken, post } from '../../netApi/fetch';
import wxpay from '../../data/pay';
import { ready } from '../Method/alipay';
interface props extends RouteComponentProps {
    user: user
    accesstoken?: string
}

const Borad = styled.div`
    position: fixed; 
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 256px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    background-color: #f5f5f5;
    padding: 6px 3px;
    box-sizing: border-box;
    &>div{
        user-select: none;
        background-color: #fff;
        border-radius: 3px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: background-color .2s;
        &:active{
            background-color: #f5f5f5;
        }
    }
`

function Index(props: props) {
    // 页面title
    useEffect(() => {
        document.title = '支付成功'
        document.body.style.backgroundColor = '#fff'
        return () => {
            document.title = ''
            document.body.style.backgroundColor = '#fff'
        }
    }, [])

    const price = parseParams(props.location.search).price || ''

    return <>
        <div className="flex-center" style={{ paddingTop: 100, marginBottom: 30, }}>
            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13173" width="32" height="32"><path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#FF5156" p-id="13174"></path><path d="M476.16 600.9856l217.224533-217.224533a34.133333 34.133333 0 1 1 48.264534 48.264533l-241.3568 241.322667a34.133333 34.133333 0 0 1-52.770134-5.461334 34.269867 34.269867 0 0 1-5.495466-4.5056l-144.827734-144.827733a34.133333 34.133333 0 0 1 48.264534-48.264533l130.696533 130.696533z" fill="#F8F8F8" p-id="13175"></path></svg>
        </div>
        <div className="flex-center" style={{ fontSize: 18, color: '#333', fontWeight: 600, marginBottom: 30 }} >
            支付成功
        </div>
        <div className="flex-center" style={{alignItems: 'baseline'}}>
            <span style={{ fontSize: 18 }} >￥</span>
            <span style={{ fontSize: 32 }} >{Number(price).toFixed(2)}</span>
        </div>
        <div onClick={props.history.goBack} className='flex-center' style={{
            position: 'fixed', bottom: 120, left: `calc((100vw - 200px) / 2)`,
            width: 200, height: 47, fontSize: 14,
            borderRadius: 5,
            background: '#ff5156',
            color: '#fff',
        }}>
            完 成
        </div>
    </>
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        accesstoken: state.accesstoken
    }
}
export default connect(mapStateToProps,)(Index)