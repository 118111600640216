import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";


// 登录
import Login from '../component/total/Login.tsx'

import index from '../component/index/index.tsx';
import success from '../component/index/success';

class R extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path='/' exact component={index} />
            <Route path='/success' exact component={success} />
            <Route path='/:id' exact component={index} />
            <Redirect to="/" />
          </Switch>
          <Login />
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    is_Login: state.is_Login,
  }
}

export default connect(mapStateToProps,)(R);